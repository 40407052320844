import HeaderLine from "../font/headerline";
import Header from "../font/header1";
import Header2 from "../font/header2";
import Card from "../layout/card";
import Threebox from "../layout/threebox";
import CircleIcon from "../circleIcon/circleIcon";
import H4 from "../font/H4";
import doorman from "../../img/doorman.png";
import maintenance from "../../img/maintenance.png";
import dashboard from "../../img/dashboard.png"

const WhatWeDo = () => {
    return (
        <div className='flex flex-col justify-center pt-16 lg:pt-24 lg:p-10 bg-lightblue'>
            <div className='flex flex-row'>
                <HeaderLine />
                <div className='flex flex-col place-items-center flex-1 lg:flex-none'>
                    <Header>What we do</Header>
                </div>
                <HeaderLine />
            </div>
            <Header2> HomeButler handles the relationship with professional contractors, saving you time and frustration</Header2>
            <Threebox>
                <Card>
                    <CircleIcon icon={doorman} />
                    <H4> Concierge Service </H4>
                    <p>Save time and frustration by letting our team handle the contractor relationship. It's like having a property manager for your home. Just tell us what you need help with, and we'll take care of the rest.</p>
                </Card>
                <Card>
                    <CircleIcon icon={maintenance} />
                    <H4> Maintenance Plans </H4>
                    <p>Understanding the health of your home is essential. We help homeowners schedule home inspections and create a personalized maintenance and improvement plan.</p>
                </Card>
                <Card>
                    <CircleIcon icon={dashboard} />
                    <H4> Powered By Data </H4>
                    <p>Our goal is to empower you, the homeowner, to make the best decision. We harness data from the platform to provide professional reviews, availability, and price so you can make the best decision possible.</p>
                </Card>
            </Threebox>
        </div>
    )
}

export default WhatWeDo