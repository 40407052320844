import user_with_phone from '../img/user_with_phone.png'

const TopBar = () => {
    return (
    // <div className="flex h-12 my-3 mx-16">
        <div className="flex flex-row justify-between w-3/4">
          <h1 className=" text-darkblue text-4xl">First's Profile</h1>
          <img className=' h-10 w-10 rounded-full border border-b-lightblue' src={user_with_phone}></img>
        </div>
    //   </div>
    )
}

export default TopBar