function Link(props) {
 const {href, setIsNavOpen=false, textColor='black' } = props
  const handleClick = (event) => {
    event.preventDefault();
    if(setIsNavOpen) {setIsNavOpen(false)}
    const targetElement = document.querySelector(href);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 128,
        behavior: 'smooth',
      });
    }
  };

  const isActive = window.location.hash === href;
  if (textColor === 'white') {
    return (
      <a href={href} onClick={handleClick} className='text-white hover:text-blue'>{props.children}</a>
    );
  } else {
    return (
      <a href={href} onClick={handleClick} className='hover:text-blue'>{props.children}</a>
    );
  }
}

export default Link