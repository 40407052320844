import React from 'react';
import { Link } from 'react-router-dom';

const PillButton = ({ active, link, children }) => {
  return (
    // <div className={`rounded-t-lg h-14 w-44 py-4 text-center align-middle ${active ? 'bg-white text-darkblue' : 'bg-lightblue text-white'}`}>
    <Link to={link}>
        <div className={`${active ? 'text-darkblue h-14 bg-white' : 'text-white bg-extraskyblue'} rounded-t-lg  w-44 py-4 text-center align-middle `}>
        {children}
        </div>
    </Link>
  );
};


export default PillButton;
