import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function ProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    checkAuthState();
  }, []);

  async function checkAuthState() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
      setIsLoading(false);
    } catch (error) {
      console.error('Not authenticated', error);
      setUser(null);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading state component
  }

  return user ? children : <Navigate to="/" replace state={{ from: location }} />;
}

export default ProtectedRoute;
