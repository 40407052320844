import React, { useState } from 'react';
import LogoDark from '../img/LogoDark.png';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Sidebar = ({ signOut }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* Hamburger Menu */}
      <div className="md:hidden flex justify-between items-center p-4 bg-darkblue text-white">
        <Link to="/dashboard/projects">
          <img src={LogoDark} alt="Logo" className="h-8" />
        </Link>
        <button onClick={toggleSidebar} className="focus:outline-none">
          {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Sidebar for larger screens */}
      <div className={`md:flex ${isOpen ? 'flex' : 'hidden'} md:w-64 lg:w-80 p-10 bg-darkblue text-white h-full fixed flex flex-col`}>
        {/* Logo Section */}
        <div className='flex-none'>
          <div className="flex justify-center items-center h-20">
            <Link to="/dashboard/projects">
              <img src={LogoDark} alt="Logo" className="h-12" />
            </Link>
          </div>
        </div>

        {/* Navigation Links */}
        <div className="flex-grow">
          <div className='flex flex-col h-full justify-center items-center'>
            <div className='flex flex-none justify-center items-center'>
              <ul>
                <li className={`p-2 ${location.pathname === '/dashboard/projects' ? 'text-blue' : 'text-white'}`}>
                  <Link to="/dashboard/projects" onClick={handleLinkClick}>My Projects</Link>
                </li>
                <li className={`p-2 ${location.pathname.startsWith('/dashboard/settings') ? 'text-blue' : 'text-white'}`}>
                  <Link to="/dashboard/settings/account">Settings</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Logout Section */}
        <div className='flex-none'>
          <div className="flex justify-center">
            <button onClick={signOut} className=" text-white font-bold rounded-full">
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
