function ProcessImage(props) {
  return (
    <div className="drop-shadow-xl rounded-lg pt-56 w-80 lg:w-[584px] h-[295px] bg-lightblue flex justify-center items-center overflow-hidden">
        <div className="relative rounded-lg">
            <img src={props.image} className="drop-shadow-2xl w-[200px] rounded-lg " alt={props.alt} />
        </div>
    </div>
  );
}

export default ProcessImage;