import Link from "../menu/link";
import logo from "../../img/logo.png";
import HamburgerMenu from "../menu/hamburger";
import { NavLink } from "react-router-dom";

function Navbar(props) {
    const { links } = props;
    return (
        <div className='z-30 flex flex-row flex-none justify-around p-10 sticky top-0 bg-white'>
            <NavLink to='/' className='h-12 order-2 lg:order-1'>
                <img src={logo} alt="Homebutler logo" className='h-12 order-2 lg:order-1' />
            </NavLink>
            {links && (
                <div className='hidden order-2 lg:flex flex flex-row justify-between gap-12'>
                    <Link href='#section1'>Home</Link>
                    <Link href='#section2'>What we do</Link>
                    <Link href='#section25'>Services</Link>
                    <Link href='#section3'>How it works</Link>
                    <Link href='#section4'>Pricing</Link>
                    <NavLink to='/blog'>Blog</NavLink>
                </div>
            )}
            {!links && (
                <div className='hidden order-2 lg:flex flex flex-row justify-between gap-12'>
                    <NavLink to='/blog'>Blog</NavLink>
                </div>
            )}
            {links && (
                <div className='flex order-1 lg:hidden'>
                <HamburgerMenu />
            </div>
            )}
            <p className='order-3 invisible'>Get early access</p>
        </div>
    )
}

export default Navbar;