import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteProject } from '../../graphql/mutations';
import { FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project, onDelete }) => {
    const handleDelete = async () => {
        try {
            await onDelete(); // Wait for the deletion operation to complete
        } catch (error) {
            console.log({ project });
            console.error('Error deleting project:', error);
        }
    };

    return (
        <div className="md:w-64 lg:w-96 h-96 bg-white rounded-xl p-6 m-6 flex flex-col" >
            <div className="flex-none">
                <div className="flex flex-row">
                    <div className="flex-grow">
                        <h1 className="text-2xl text-darkblue font-semibold">{project.name}</h1>
                    </div>
                    <div className="flex flex-none">
                        <button onClick={handleDelete} className="text-red-500 focus:outline-none">
                            <FiTrash2 size={20} />
                        </button>
                    </div>
                </div>
                <hr className="my-6 border-t-2 border-extralightblue" />
            </div>
            <div className="flex-grow">
                <p><strong>Status:</strong> {project.status}</p>
                <p><strong>Description:</strong> {project.description}</p>
            </div>
            <div className="flex-none">
                <div className="flex justify-center">
                    <Link to={`${project.id}`} className="justify-self-center text-blue font-bold py-2 px-4 rounded-full mb-4 border border-blue">
                        View Details
                    </Link>
                </div>
                
            </div>
            
        </div>
    );
};

export default ProjectCard;
