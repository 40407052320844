import H4 from "../font/H4";
import checkmark from "../../img/icon/checkmark.png";
import crossmark from "../../img/icon/crossmark.png";

function PriceCard(props) {
    const { highlight, title } = props
    const dynamicClassName = `pt-8 bg-${highlight ? 'blue' : 'white'} ${highlight ? 'border-2 border-white' : '0'} flex flex-col items-left justify-top gap-4 p-8 w-[370px] h-[${highlight ? '560px' : '520px'}] rounded-lg`;
    const priceColor = `font-semibold text-${highlight ? 'white' : 'blue'} text-4xl`
    const billedAnnuallyColor = `-mt-4 text-${highlight ? 'white' : 'greydark'}`
    const hrClassName = `border-t-1 border-${highlight ? 'grey2' : 'grey2'} w-8/10`
    const listTextColor = `text-blue}`

    const handleButtonClick = () => {
        console.log("Price Card setting focus to be true")
        props.setFocusToEmailInput(true); // Set focus to email input in EmailCaptureComponent
      };

    return (
        <div key="1" className={dynamicClassName}>
                {highlight && <div className="h-[4px]"></div>}
                <H4>{title}</H4>
            
            <p className={priceColor}>{props.price}
                <span className="text-2xl">/month</span>
            </p>
            <p className={billedAnnuallyColor}>Billed annually</p>
            <hr className={hrClassName} />
            <div className="p-4">
            {props.features.map((feature, id) => (
                <div key={id} className="flex flex-row items-center justify-left gap-2 py-2">
                    {feature.enabled && <img src={checkmark} alt="checkmark" />}
                    {!feature.enabled && <img src={crossmark} alt="crossmark" />}
                    <p className={listTextColor}>{feature.title}</p>
                </div>
            ))}
            </div>
            <button onClick={handleButtonClick} className='bg-pink rounded-full h-14 text-white'>Get early access</button>
        </div>
    )
}

export default PriceCard