import CircleIcon from '../circleIcon/circleIcon';
import H4 from '../font/H4';
import Header from '../font/header1';
import ServiceCard from '../layout/serviceCard';
import HeaderLine from '../font/headerline';
import Header2 from '../font/header2';
import appliance from '../../img/services/appliance.svg';
import electrical from '../../img/services/electrical.svg';
import hvac from '../../img/services/hvac.svg';
import plumbing from '../../img/services/plumbing.svg';
import roofing from '../../img/services/roofing.svg';
import flooring from '../../img/services/flooring.svg';
import other from '../../img/services/other.svg';

const Services = () => {

    const services = [
        {
            name: 'Appliance Repair',
            icon: appliance,
        },
        {
            name: 'Electrical',
            icon: electrical,
        },
        {
            name: 'HVAC',
            icon: hvac,
        },
        {
            name: 'Plumbing',
            icon: plumbing,
        },
        {
            name: 'Roofing',
            icon: roofing,
        },
        {
            name: 'Flooring',
            icon: flooring,
        },
        {
            name: 'Other',
            icon: other,
        },
    ];

    return (
        <>
            <div className='flex flex-row'>
                <HeaderLine />
                <div className='flex flex-col place-items-center flex-1 lg:flex-none'>
                    <Header white='True'>Our Main Services</Header>
                </div>
                <HeaderLine />
            </div>
            <Header2>See what services HomeButler provides</Header2>
            <div className="max-w-7xl mx-auto px-4 lg:px-0">
                <div className="flex flex-wrap justify-center gap-10 lg:gap-4">
                    {/* ServiceCard components */}
                    {services.map((service, index) => (
                        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5">
                            <ServiceCard>
                                <CircleIcon icon={service.icon}/>
                                <H4>{service.name}</H4>
                            </ServiceCard>
                        </div>
                    ))}
                </div>
            </div>


        </>
    );
};

export default Services;
