import { useState } from "react"

const NewQuoteForm = ({ onSubmit, onCancel }) => {
    const [newQuote, setNewQuote] = useState({
        company: '',
        price: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewQuote({ ...newQuote, [name]: value });
    };

    const handleSubmit = () => {
        onSubmit(newQuote);
        setNewQuote({
            company: '',
            price: ''
        });
    };

    return (
        <div className="w-72 lg:w-96 h-96 bg-lightblue rounded-xl p-6">
            <h1 className="text-2xl text-darkblue font-semibold mb-4">New Quote</h1>
            <hr className="my-2 border-t-2 border-extralightblue" />
            <div>
                <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    className="w-full border rounded-md mb-4 px-4 py-2"
                    value={newQuote.company}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="price"
                    placeholder="Price"
                    className="w-full border rounded-md mb-4 px-4 py-2"
                    value={newQuote.price}
                    onChange={handleInputChange}
                />
                <div className="flex justify-end">
                    <button
                        className="bg-pink text-white font-bold py-2 px-4 rounded-full mr-4"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                    <button
                        className="bg-white text-blue font-bold py-2 px-4 rounded-full"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NewQuoteForm
