import React from 'react';
import Link from '../menu/link';
import { NavLink } from 'react-router-dom';
import LogoDark from "../../img/LogoDark.png";
import EmailCaptureComponent from '../forms/emailCapture';

const Footer = (props) => {
    const { links } = props;
    return (
        <footer className='z-30 flex flex-row flex-none justify-around p-10 sticky top-0 bg-darkblue border-t-2 border-bluegrey'>
            <NavLink to='/' className='h-12 order-2 lg:order-1'>
                <img src={LogoDark} alt="Homebutler logo" className='h-12 order-2 lg:order-1' />
            </NavLink>
            {links && (
                <div className='hidden order-2 lg:flex flex flex-row justify-between gap-12'>
                    <Link textColor='white' href='#section1'>Home</Link>
                    <Link textColor='white' href='#section2'>What we do</Link>
                    <Link textColor='white' href='#section25'>Services</Link>
                    <Link textColor='white' href='#section3'>How it works</Link>
                    <Link textColor='white' href='#section4'>Pricing</Link>
                    <NavLink className='text-white hover:text-blue' to='/blog'>Blog</NavLink>
                </div>
            )}
            {!links && (
                <div className='hidden order-2 lg:flex flex flex-row justify-between gap-12'>
                    <NavLink className='text-white hover:text-blue' to='/blog'>Blog</NavLink>
                </div>
            )}
            <p className='order-3 invisible'>Get early access</p>
        </footer>
    );
};

export default Footer;
