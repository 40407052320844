const CompanyInfoLine = ({icon, header, text}) => {
    return (
        <div className='flex flex-row gap-x-1'>
            <img src={icon} alt={`${header} icon`} className='self-start' />
            <p className='text-darkblue font-semibold'>{header}: </p>
            <p>{text}</p>
        </div>
    )
}

export default CompanyInfoLine