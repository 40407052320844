import Link from "../menu/link";
import Card from "../layout/card";
import H4 from "../font/H4";
import ReactMarkdown from "react-markdown";
import { Storage } from 'aws-amplify';
import { useState, useEffect } from 'react';

function previewSentence(sentence) {
    if (sentence.length <= 200) {
      return sentence;
    } else {
      return sentence.slice(0, 200) + '...';
    }
  }

function BlogPreview(props) {
  const { post } = props;
  const postPreview = previewSentence(post.content);
  console.log(post);

  const [imageUrl, setImageUrl] = useState('');
  const [isPostLoaded, setIsPostLoaded] = useState(false);

  useEffect(() => {
    async function fetchImage() {
      try {
        const url = await Storage.get(post.image);
        setImageUrl(url);
        console.log('url', url);
      } catch (error) {
        console.log('Error fetching image:', error);
      }
    }
    fetchImage().then(() => setIsPostLoaded(true));
  }, [post.id]);

  if (!isPostLoaded) {
    return <div>Loading...</div>; // Replace with a loading indicator or skeleton UI
  }

  return (
    <div className="drop-shadow-xl bg-lightblue flex flex-col items-center justify-top gap-4 w-80 rounded-lg">
            {imageUrl && (
        <div className="h-36 rounded-t-lg overflow-hidden">
          <img
            src={imageUrl}
            alt={post.title}
            className="object-contain"
          />
        </div>
      )}
            <div className="flex flex-col px-8 pb-8 gap-4">
                <H4> {post.title} </H4>
                <ReactMarkdown>{postPreview}</ReactMarkdown>
            </div>
    </div>
  );
}

export default BlogPreview;