import { useState, useRef } from 'react';
import Header from '../../components/font/header1.js';
import HeaderLine from '../../components/font/headerline.js';
import Header2 from '../../components/font/header2.js';
import SubHeader from '../../components/font/subheader';
import EmailCaptureComponent from '../../components/forms/emailCapture.js';
import user_with_phone from '../../img/user_with_phone.png'
import Threebox from '../../components/layout/threebox.js';
import ProcessInfo from '../../components/layout/processInfo.js';
import H3 from '../../components/font/H3.js';
import Process from '../../components/layout/process.js';
import project from '../../img/projects.png';
import selection from '../../img/selection.png';
import company from '../../img/company.png';
import ProcessImage from '../../components/layout/processImage.js';
import PriceCards from '../../components/PriceCards/PriceCards.js';
import Navbar from '../../components/navbar/Navbar.js';
import Footer from '../../components/footer/Footer.js';
import TagManager from 'react-gtm-module';
import SignupSuccessModal from '../../components/PopupWaitlistConfirm/PopupWaitlistConfirm.js';
import WhatWeDo from '../../components/whatWeDo/WhatWeDo.js';
import Services from '../../components/services/Services.js';
 
const tagManagerArgs = {
    dataLayer: {
        page: 'home'
    },
    dataLayerName: 'PageDataLayer'
}

function Homepage() {
    
    TagManager.dataLayer(tagManagerArgs)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusEmailInput, setFocusEmailInput] = useState(false);
    const emailInputRef = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div>
                <Navbar links={true}/>
                <SignupSuccessModal isOpen={isModalOpen} onClose={closeModal}/>
                <div id='section1' className='flex flex-col lg:flex-row lg:justify-evenly justify-center pl-12 pr-12'>
                    <div className='flex flex-col lg:w-1/3'>
                        <SubHeader>HomeButler</SubHeader>
                        <Header>Home Maintenance Made Easy</Header>
                        <p className='pt-5'>The way home maintenance should be. HomeButler is a platform created to take the pain out of home maintenance. We combine the best contractors, seamless scheduling, homeowner choice, and simple payment to help homeowners maintain their homes.</p>
                        <EmailCaptureComponent openModal = {openModal} emailInputRef={emailInputRef} focusEmailInput={focusEmailInput} setFocusEmailInput={setFocusEmailInput}/>
                    </div>
                    <div className='lg:place-self-end place-self-center'>
                        <img src={user_with_phone} alt="person with phone" className='h-96 place-self-center ' />
                    </div>
                </div>
                <div id='section2' className='z-0 h-fit bg-lightblue'>
                    <WhatWeDo />
                </div>
                <div id='section25' className='flex flex-col justify-center py-16 lg:py-24 lg:p-10 bg-darkblue'>
                    <Services />
                </div>
                <div id='section3' className='flex flex-col justify-center pt-16 lg:pt-24 lg:p-10'>
                    <div className='flex flex-row'>
                        <HeaderLine />
                        <div className='flex flex-col place-items-center flex-1 lg:flex-none'>
                            <Header>How it works</Header>
                        </div>
                        <HeaderLine />
                    </div>
                    <Header2>HomeButler helps you throughout the entire maintenance journey</Header2>
                    <Process>
                        <ProcessInfo>
                            <H3>1. Tell us how to help</H3>
                            <p>Add a project to your dashboard. Our team will research the best contractors in your area to schedule quotes based on your availability.</p>
                        </ProcessInfo>
                        <ProcessImage image={project} alt="Project icon" />
                    </Process>
                    <Process>
                        <ProcessInfo reverse='True'>
                            <H3>2. Select the best quote</H3>
                            <p>Once all the quotes are received, you will see the pros rating, price, and availability to make the best decision for you.</p>
                        </ProcessInfo>
                        <ProcessImage image={selection} alt="Home icon" />
                    </Process>
                    <Process>
                        <ProcessInfo>
                            <H3>3. Pro completes work</H3>
                            <p>After you select the quote, HomeButler works with professional contractors to schedule and complete the work.</p>
                        </ProcessInfo>
                        <ProcessImage image={company} alt="Data icon" />
                    </Process>
                </div>
                <div id='section4' className='flex flex-col justify-center pt-16 lg:pt-24 lg:p-10 bg-darkblue'>
                    <div className='flex flex-row'>
                        <HeaderLine />
                        <div className='flex flex-col place-items-center flex-1 lg:flex-none'>
                            <Header white='True'>Pricing</Header>
                        </div>
                        <HeaderLine />
                    </div>
                    <Header2>Choose what plan is best for You</Header2>
                    <Threebox>
                        <PriceCards setFocusToEmailInput={setFocusEmailInput} />
                    </Threebox>
                </div>
                <Footer links={true}/>
            </div>
        </>
    )
};

export default Homepage;