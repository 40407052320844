import React, { useEffect, useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { getBlog } from '../graphql/queries';
import { useParams } from 'react-router-dom';
import Navbar from '../components/navbar/Navbar';
import ReactMarkdown from 'react-markdown';
import Footer from '../components/footer/Footer';


function BlogPostPage() {
    const [post, setPost] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const { postId } = useParams();
    const markdown = `
  # Header 1
  ## Header 2

  _ italic _

  ** bold **

  <b> bold Html </b>
  `;

    useEffect(() => {
        fetchItem();
    }, []);

    const fetchItem = async () => {
        try {
            console.log("fetching item");
            const postData = await API.graphql({
                query: getBlog,
                variables: { id: postId },
            });
            setPost(postData.data.getBlog);
            const url = await Storage.get(postData.data.getBlog.image);
            setImageUrl(url);
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    return (
        <>
        <Navbar links={false} />
        <div className='flex flex-col items-center mb-10'>
            {post && imageUrl && <img src={imageUrl} alt={post.title} className='mb-8 w-full object-cover max-h-96' />}
            {post && <h1 className='text-3xl font-bold my-8'>{post.title}</h1>}
            {post && <ReactMarkdown className='prose'>{post.content}</ReactMarkdown>}
        </div>
        <Footer />
        </>
    );
}

export default BlogPostPage;