import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Homepage from './pages/homepage/Homepage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import CityPage from './pages/citypages/CityPage';
import Dashboard from './pages/dashboard/dashboard';
import { initGA, logPageView } from './utils/analytics';
import ProtectedRoute from './components/protectedRoute';
import Login from './components/login';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
};

const App = () => {
  useEffect(() => {
    initGA('G-ZR4NMSMV7K');
  }, []);

  return (
    <Router>
      <PageTracker />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:postId" element={<BlogPostPage />} />
        <Route path="/locations/:cityName" element={<CityPage />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        {/* <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} /> */}
        {/* <Route path="/login" element={<Login />}/> */}
      </Routes>
    </Router>
  );
};

const PageTracker = () => {
  usePageTracking();
  return null;
};

export default App;
