// src/components/MainContent.js
import React from 'react';

const MainContent = ({ children }) => {
  return (
    <div className="md:ml-64 lg:ml-80 px-8 pt-8 w-full h-screen overflow-y-auto fixed bg-lightblue">
      {children}
    </div>
  );
};

export default MainContent;
