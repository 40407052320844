function Header(props) {
    const { white } = props
    if (white) {
        return (
            <h2 className="text-5xl lg:text-5xl font-semibold text-white">{props.children}</h2>
        )
    }
    return (
        <h2 className="text-5xl lg:text-5xl font-semibold text-darkblue">{props.children}</h2>
    )
}

export default Header