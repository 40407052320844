// NewProjectForm.js
import React, { useState } from 'react';

const NewProjectForm = ({ onSubmit, onCancel }) => {
    const [newProject, setNewProject] = useState({ name: '', status: '' });

    const statusOptions = [
        'Created',
        'In progress',
    ];
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProject({ ...newProject, [name]: value });
    };

    const handleSubmit = () => {
        onSubmit(newProject);
        setNewProject({ name: '', status: '' });
    };

    return (
        <div className="md:w-64 lg:w-96 h-96 bg-lightblue rounded-xl py-6 px-6 m-6">
            <h1 className="text-2xl text-darkblue font-semibold mb-4">New Project</h1>
            <hr className="my-2 border-t-2 border-extralightblue" />
            <div>
                <input type="text" name="name" placeholder="Title" className="w-full border rounded-md mb-4 px-4 py-2" value={newProject.name} onChange={handleInputChange}/>
                <select 
                    name="status" 
                    className="w-full border rounded-md mb-4 px-4 py-2" 
                    value={newProject.status} 
                    onChange={handleInputChange}
                >
                    <option value="" disabled>Select Status</option>
                    {statusOptions.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                    ))}
                </select>
                <input type="text" name="description" placeholder="Description" className="w-full border rounded-md mb-4 px-4 py-2" value={newProject.description} onChange={handleInputChange}/>
                <div className="flex justify-end">
                    <button className="bg-pink text-white font-bold py-2 px-4 rounded-full mr-4" onClick={handleSubmit}>
                        Save
                    </button>
                    <button className="bg-white text-blue font-bold py-2 px-4 rounded-full" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewProjectForm;
