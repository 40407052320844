/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://csswtd3eqfb3beua4fjvadvfee.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i5hrjwjlqvd73fmavy6cdfgnca",
    "aws_cognito_identity_pool_id": "us-east-1:72917bd8-d4af-43ae-8224-7770be7c95fa",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xPG2rcR9V",
    "aws_user_pools_web_client_id": "5057uvtfju2jbna0ffjat6q6lb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "landing-storage-29f79e55175029-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
