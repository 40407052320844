import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { useParams } from 'react-router-dom';
import HeroSection from '../../components/hero/hero';
import atlanta from '../../img/atlanta.jpg'
// import HeroSection from '../../components/atlanta/HeroSection';
// import ServicesOffered from '../../components/atlanta/ServicesOffered';
// import ServiceAreas from '../../components/atlanta/ServiceAreas';
// import Testimonials from '../../components/atlanta/Testimonials';
// import LocalPartnerships from '../../components/atlanta/LocalPartnerships';
import WhatWeDo from '../../components/whatWeDo/WhatWeDo';

function CityPage() {
    const { cityName } = useParams();
    const cityData = {
        'atlanta': {
            cityName: 'Atlanta',
            tagline: 'Your Home Maintenance Partner in Atlanta',
            backgroundImage: atlanta, // Path to your Atlanta background image
            description: 'Welcome to HomeButler Atlanta! We provide top-notch home maintenance services tailored to Atlanta and its suburbs. Discover how we can simplify home care for you.',
            ctaText: 'Explore Services',
        }
    };

    // const cityName = 'Atlanta';
    const currentCityData = cityData[cityName];
    return (
        <div>
            <Navbar />
            <HeroSection currentCityData={currentCityData} />
            <WhatWeDo />
            {/* <HeroSection />
        <ServicesOffered />
        <ServiceAreas />
        <Testimonials />
        <LocalPartnerships /> */}
            <Footer />
        </div>
    );
}

export default CityPage;
