import React, { useState, useEffect } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Users, UserStatus } from '../../models';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';


Amplify.configure(awsconfig);

const EmailCaptureComponent = ({ openModal, focusEmailInput, emailInputRef, setFocusEmailInput }) => {
   // Ref to the email input field

  useEffect(() => {
    if (focusEmailInput) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      emailInputRef.current.focus();
      setFocusEmailInput(false)
    }
  }, [focusEmailInput, emailInputRef, setFocusEmailInput]);
  
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    
    setEmail(e.target.value);
  };

  const handleSaveClick = async() => {
    // Here, you can perform any action to save the email
    // For simplicity, let's just log it to the console
    console.log('Email captured:', email);
    try {
        const response = await DataStore.save(
          new Users({
            email: email,
            status: UserStatus.WAITLIST,
          })
        );
    
        console.log(response);
        openModal();
        setEmail('')
      } catch (error) {
        console.log(error);
      }
  };

  return (
    <div className='flex lg:flex-row flex-col gap-4 p-4'>
      <input ref={emailInputRef} type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email"
        className='bg-lightgrey rounded-full lg:flex-1 pl-8 pr-12 h-14 focus:outline-none' />
      <button onClick={handleSaveClick}
        className='bg-pink rounded-full lg:flex-1 h-14 lg:-ml-16'>Get early access</button>
    </div>
  );
};

export default EmailCaptureComponent;
