import React, { useState, useEffect } from 'react';
import ProjectCard from "../../components/project/projectCard";
import NewProjectForm from '../../components/forms/newProjectForm';
import { API } from 'aws-amplify';
import { createProject, updateProject, deleteProject} from '../../graphql/mutations';
import { listProjects } from '../../graphql/queries';

const ProjectPage = () => {
    const [showNewProjectForm, setShowNewProjectForm] = useState(false);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleNewProjectSubmit = async (newProject) => {
        try {
            await API.graphql({
                query: createProject,
                variables: {
                    input: newProject
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            await fetchProjects();
            setShowNewProjectForm(false);
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };

    const fetchProjects = async () => {
        try {
            const result = await API.graphql({
                query: listProjects,
                variables: {
                    filter: {
                        _deleted: { ne: true }
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            console.log(result.data.listProjects.items)
            setProjects(result.data.listProjects.items);
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    const handleDeleteProject = async (projectId, version) => {
        try {
            const result = await API.graphql({
                query: deleteProject,
                variables: {
                    input: {
                        id: projectId,
                        _version: version
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            await fetchProjects(); // Refresh projects after deletion
            return result; // Return the result of the deletion operation
        } catch (error) {
            console.error('Error deleting project:', error);
            throw error; // Re-throw the error to be caught by the caller
        }
    };

    return (
        <div className='flex flex-col h-full'>
            <h1 className="text-left mb-8">Project Page</h1>
            <button
                className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded-full mb-4 max-w-fit"
                onClick={() => setShowNewProjectForm(true)}
            >
                Add New Project
            </button>

            <div className="flex-grow overflow-y-auto flex flex-wrap justify-start">
                {showNewProjectForm && (
                    <NewProjectForm
                        onSubmit={handleNewProjectSubmit}
                        onCancel={() => setShowNewProjectForm(false)}
                    />
                )}

                {projects.map((project, index) => (
                    <ProjectCard
                        key={index}
                        project={project}
                        onDelete={() => handleDeleteProject(project.id, project._version)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProjectPage;
