// components/quoteCard.js
import React from 'react';
import email from '../img/icon/email.svg';
import phone  from '../img/icon/phone.jpg';
import location from '../img/icon/location.svg'
import CompanyInfoLine from './companyInfoLine';
import { FiTrash2 } from 'react-icons/fi';

const QuoteCard = ({ quote, onDelete }) => {
  const handleDelete = async () => {
      try {
          await onDelete(); // Wait for the deletion operation to complete
      } catch (error) {
          console.log({ quote });
          console.error('Error deleting project:', error);
      }
  };
  return (
    <div className='flex flex-col p-6 rounded-lg bg-lightblue w-72 md:w-96 gap-y-4'>
        <div className="flex flex-row">
                    <div className="flex-grow">
                        <h1 className="text-2xl text-darkblue font-semibold">Quote Information</h1>
                    </div>
                    <div className="flex flex-none">
                        <button onClick={handleDelete} className="text-red-500 focus:outline-none">
                            <FiTrash2 size={20} />
                        </button>
                    </div>
                </div>
        <p className=' text-2xl text-blue font-light'>{quote.company}</p>
        <div className='flex flex-col gap-y-1'>
            <CompanyInfoLine icon={email} header="Website" text={quote.website} />
            <CompanyInfoLine icon={location} header="Address" text={quote.address} />
            <CompanyInfoLine icon={phone} header="Phone" text={quote.phone} />
            <CompanyInfoLine icon={email} header="Email" text={quote.email} />
        </div>
       
    </div>
  );
};

export default QuoteCard;
