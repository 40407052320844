import { Routes, Route, Navigate } from 'react-router-dom';
import AccountPills from '../../components/accountPills.js';
import TopBar from '../../components/topBar.js';
import Account from './settings/account.js';
import SettingsProperty from './settings/property.js';

const SettingsPage = (user) => {
  return (
    <div className="flex flex-col h-full">
      <TopBar />
      <div className='mx-[-32px] mt-12 flex-grow'>
        <AccountPills />
        <div className='bg-white flex-grow h-full'>
          <Routes>
            <Route path="/account" element={<Account user={user} />} />
            <Route path="/property" element={<SettingsProperty />} />
            <Route path="/" element={<Navigate to="/dashboard/settings/account" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;