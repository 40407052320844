import React, { useState, useRef } from 'react';
import SubHeader from '../font/subheader';
import Header from '../font/header1';
import EmailCaptureComponent from '../forms/emailCapture';
import SignupSuccessModal from '../PopupWaitlistConfirm/PopupWaitlistConfirm';

const HeroSection = ({ currentCityData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [focusEmailInput, setFocusEmailInput] = useState(false);
    const emailInputRef = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <SignupSuccessModal isOpen={isModalOpen} onClose={closeModal}/>
            <div id='section1' className='flex flex-col lg:flex-row lg:justify-evenly justify-center pl-12 pr-12'>
                <div className='flex flex-col lg:w-1/3'>
                    <SubHeader>HomeButler</SubHeader>
                    <Header>Welcome to {currentCityData.cityName}</Header>
                    <p className='pt-5'>{currentCityData.description}</p>
                    <EmailCaptureComponent openModal = {openModal} emailInputRef={emailInputRef} focusEmailInput={focusEmailInput} setFocusEmailInput={setFocusEmailInput}/>
                </div>
                <div className='lg:place-self-end place-self-center'>
                    <img className='rounded-lg max-h-96' src={currentCityData.backgroundImage} alt="Atlanta City" />
                </div>
            </div>
        </>
    );
};

export default HeroSection;
