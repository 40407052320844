import { useParams, Routes, Route, Navigate } from "react-router-dom";

const Files = () => {
    const { projectId } = useParams();
    return (
        <div className="flex flex-grow">
            <p>This is the Files page for {projectId}</p>
        </div>
    )
}

export default Files