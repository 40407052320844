function ProcessInfo(props) {
    const {reverse} = props
    if (reverse) {
        return (
            <div className="order-last justify-top w-72 lg:ml-[166px] my-9 lg:my-0">
                {props.children}
            </div>
        )
    }
    return (
        <div className="order-last lg:order-first justify-top w-72 lg:mr-[166px]  my-9 lg:my-0">
            {props.children}
        </div>
    )
}

export default ProcessInfo