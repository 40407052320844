import React, { useState } from 'react';
import Sidebar from '../../components/sidebar';
import MainContent from '../../components/mainContent';
import ProjectPage from './project';
import SettingsPage from './settings';
import ProjectDetailsPage from './projectDetails';
import awsExports from "../../aws-exports"
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';


// import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

function Dashboard({ signOut, user }) {
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/'); // Redirect to homepage
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
    <div className="flex">
        <Sidebar signOut={handleSignOut} />
        <MainContent>
          <Routes>
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/projects/:projectId/*" element={<ProjectDetailsPage />} />
            <Route path="/settings/*" element={<SettingsPage user={user} />} />
            <Route path="/" element={<Navigate to="/dashboard/projects" />} />
          </Routes>
        </MainContent>
      </div>
    );
}

export default withAuthenticator(Dashboard);
