import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { listBlogs } from '../graphql/queries';
import Navbar from '../components/navbar/Navbar';
import Card from '../components/layout/card';
import H4 from '../components/font/H4';
import ReactMarkdown from 'react-markdown';
import BlogPreview from '../components/blogPreview/BlogPreview';

function BlogPosts() {
    const [posts, setPosts] = useState([]);


    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const allBlogs = await API.graphql({ query: listBlogs });
            console.log(allBlogs.data.listBlogs.items);
            setPosts(allBlogs.data.listBlogs.items);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <Navbar links={false} />
            <h2 className='m-8 lg:mx-20'>Blog Posts</h2>
            <div className="m-8 lg:mx-20 flex flex-wrap gap-2">
                {posts && posts.map((post) => (
                    <div key={post.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4">
                        <Link to={`/blog/${post.id}`}>
                            <BlogPreview post={post} />
                        </Link>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default BlogPosts;
