import React from 'react';

function SignupSuccessModal({ isOpen, onClose }) {
  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${isOpen ? 'visible' : 'hidden'}`}>
      <div className="fixed inset-0 bg-greydark opacity-75"></div>
      <div className="relative bg-white rounded-lg p-8 text-center">
        <h2 className="text-2xl font-bold mb-4">Thank you for signing up!</h2>
        <p className=" mb-4">You have successfully joined the waitlist.</p>
        <button 
          className="bg-pink rounded-full h-14 w-32" // Adjust width to fit your design
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default SignupSuccessModal;

