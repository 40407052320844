import { API } from "aws-amplify";
import NewQuoteForm from "../../../components/forms/newQuoteForm";
import QuoteCard from "../../../components/quoteCard";
import { useEffect, useState } from "react";
import { createQuote, deleteQuote } from "../../../graphql/mutations";
import { quotesByProjectID } from "../../../graphql/queries";

const Quotes = ({ project }) => {
    const [showNewQuoteForm, setShowNewPQuoteForm] = useState(false);
    const [quotes, setQuotes] = useState([]);

    useEffect(() => {
        if (project && project.id) {
            fetchQuotes();
        }
    }, [project]);

    const fetchQuotes = async() => {
        try {
            console.log(project.id)
            const result = await API.graphql({
                query: quotesByProjectID,
                variables: {
                    projectID: project.id,
                    filter: {
                        _deleted: { ne: true }
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            console.log(result.data.quotesByProjectID.items)
            setQuotes(result.data.quotesByProjectID.items);
        } catch (error) {
            console.error('Error fetching quotes:', error);
        }
    }

    const handleDeleteQuote = async (quoteId, version) => {
        try {
            const result = await API.graphql({
                query: deleteQuote,
                variables: {
                    input: {
                        id: quoteId,
                        _version: version
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            await fetchQuotes();
            return result;
        } catch (error) {
            console.error('Error deleting quote: ', error)
        }
    }

    const handleNewQuoteSubmit = async(newQuote) => {
        try {
            console.log(project)
            const quoteWithProjectId = {
                ...newQuote,
                projectID: project.id // Assuming `project.id` is the project ID
            };
            console.log(quoteWithProjectId);
            await API.graphql({
                query: createQuote,
                variables: {
                    input: quoteWithProjectId
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            await fetchQuotes;
            setShowNewPQuoteForm(false);
        } catch (error){
            console.error('Error creating project:', error);
        }
    }


    return (
      <div className="py-8 px-12 flex flex-col gap-6">
        <div className="px-4">
            <button 
                className="text-lg bg-pink hover:bg-darkblue text-white py-3 px-10 rounded-full"
                onClick={() => setShowNewPQuoteForm(true)}    
            >
                Add Quote
            </button>

        </div>
        <div className="flex flex-row flex-wrap h-80 p-4 gap-12">
            {showNewQuoteForm && (
                <NewQuoteForm onSubmit={handleNewQuoteSubmit} onCancel={() => setShowNewPQuoteForm(false)} />
            )}
            {quotes.map((quote, index) => (
                <QuoteCard key={index} quote={quote} onDelete={() => handleDeleteQuote(quote.id, quote._version)} />
            ))}
        </div>
    </div>
    );
  }
  
  export default Quotes;
  