import React from 'react';
import PriceCard from '../layout/priceCard';


function PriceCards({setFocusToEmailInput}) {
  const level1 = {
    title: 'Bronze',
    id: 1,
    price: '$19',
    highlighted: false,
    features: [
      {
        title: '1 project per month',
        enabled: true,
      },
      {
        title: 'Priority booking',
        enabled: false
      },
      {
        title: 'Priority support',
        enabled: false
      },
      {
        title: 'Annual home inspection for $400',
        enabled: true
      },
      {
        title: 'Additional projects for $9',
        enabled: true
      }
    ]
  }
  const level2 = {
    title: 'Silver',
    id: 2,
    price: '$29',
    highlighted: true,
    features: [
      {
        title: '3 project per month',
        enabled: true,
      },
      {
        title: 'Priority booking',
        enabled: true
      },
      {
        title: 'Priority support',
        enabled: true
      },
      {
        title: 'Annual home inspection for $200',
        enabled: true
      },
      {
        title: 'Additional projects for $9',
        enabled: true
      }
    ]
  }
  const level3 = {
    title: 'Gold',
    id: 3,
    price: '$99',
    highlighted: false,
    features: [
      {
        title: 'Unlimited projects per month',
        enabled: true,
      },
      {
        title: 'Priority booking',
        enabled: true
      },
      {
        title: 'Priority support',
        enabled: true
      },
      {
        title: 'Free annual home inspection',
        enabled: true
      },
      {
        title: 'Additional projects for free',
        enabled: true
      }
    ]
  }

  const levels = [level1, level2, level3];

  return (
    <>
    {levels.map((level) => {
      return (
        <PriceCard setFocusToEmailInput = {setFocusToEmailInput}
          title={level.title}
          price={level.price}
          highlight={level.highlighted}
          features={level.features}
          key={level.id}
        />
      )
    }
    )}
    </>
  );
}

export default PriceCards;
