import { useParams, Routes, Route, Navigate } from "react-router-dom";
import PillButton from "../../components/button/pill";
import TopBar from "../../components/topBar";
import General from "./project/general";
import Files from "./project/files";
import Quotes from "./project/quotes";
import { useLocation, Link } from "react-router-dom";
import { getProject } from '../../graphql/queries';
import { useEffect, useState } from "react";
import { API } from 'aws-amplify';

function ProjectDetailsPage() {
    const { projectId } = useParams();
    const [project, setProject] = useState([])
    const location = useLocation();

    const fetchProject = async () => {
        try {
            const result = await API.graphql({
                query: getProject,
                
                variables: { id: projectId },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            setProject(result.data.getProject)
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    };

    useEffect(() => {
        fetchProject();
    }, [])

    return (
        <div className="flex flex-col h-full">
            <TopBar />
            <div className='mx-[-32px] mt-12 flex-grow'>
                <div className='flex flex-row pl-16'>
                    <PillButton link={`/dashboard/projects/${projectId}/general`} active={location.pathname === `/dashboard/projects/${projectId}/general`}>
                        General
                    </PillButton>
                    <PillButton link={`/dashboard/projects/${projectId}/files`} active={location.pathname === `/dashboard/projects/${projectId}/files`}>
                        Files
                    </PillButton>
                    <PillButton link={`/dashboard/projects/${projectId}/quotes`} active={location.pathname === `/dashboard/projects/${projectId}/quotes`}>
                        Quotes
                    </PillButton>
                </div>
                <div className='bg-white flex-grow flex overflow-y-auto h-5/6'>
                    <Routes>
                        <Route path="/general" element={<General project={project} />} />
                        <Route path="/files" element={<Files />} />
                        <Route path="/quotes" element={<Quotes project={project} />} />
                        <Route path="/" element={<Navigate to={`/dashboard/projects/${projectId}/general`} />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetailsPage