import upload_icon from "../../../img/upload.png"
import React, { useState, useEffect, useRef } from 'react';
import { Auth, Storage } from 'aws-amplify';

const Account = (user) => {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [userAttributes, setUserAttributes] = useState(null)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmial] = useState('');

    useEffect(() => {
        fetchMostRecentProfilePhoto();
    }, []);

    useEffect(() => {
        const attributes = user.user.user.attributes;
        setUserAttributes(attributes);
        setFirstName(attributes.given_name || '');
        setLastName(attributes.family_name || '');
        setPhoneNumber(attributes.phone_number || '');
        setEmial(attributes.email || '');
    }, [user]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        handleUpload(file);
    };

    const handleUpload = async (file) => {
        if (file) {
            const fileExtension = file.name.split('.').pop();
            const fileName = `profile/profile.${fileExtension}`;

            try {
                const result = await Storage.put(fileName, file, {
                    level: 'private',
                    contentType: file.type,
                });
                console.log('File uploaded successfully', result);
                await fetchMostRecentProfilePhoto();  // Update the profile photo after uploading
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
    };

    const updateProfile = async (event) => {
        event.preventDefault(); // Prevent form submission
        const formData = new FormData(event.target);
        const updatedAttributes = {};

        const firstName = formData.get('firstName');
        const lastName = formData.get('lastName');
        const phoneNumber = formData.get('phoneNumber');
        const email = formData.get('email');

        if (firstName) updatedAttributes['given_name'] = firstName;
        if (lastName) updatedAttributes['family_name'] = lastName;
        if (email) updatedAttributes['email'] = email;

        if (phoneNumber) {
            const formattedPhoneNumber = `+1${phoneNumber.replace(/\D/g, '')}`;
            if (/^\+\d{10,15}$/.test(formattedPhoneNumber)) {
                updatedAttributes['phone_number'] = formattedPhoneNumber;
            } else {
                console.log('Invalid phone number format.');
                return;
            }
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, updatedAttributes);
            console.log('Attributes updated');
        } catch (error) {
            console.log('error updating attributes:', error);
        }
    };


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const fetchMostRecentProfilePhoto = async () => {
        try {
            const result = await Storage.list('profile/', { level: 'private', maxKeys: 2 });
            if (result.results.length > 0) {
                const mostRecentFile = result.results.reduce((latest, file) => {
                    return new Date(file.lastModified) > new Date(latest.lastModified) ? file : latest;
                });
                const signedUrl = await Storage.get(mostRecentFile.key, { level: 'private' });
                setProfileImageUrl(signedUrl);
            }
        } catch (error) {
            console.error('Error fetching profile photo', error);
        }
    };
    
    const formatPhoneNumber = (value) => {
        // Remove all non-digit characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Split the cleaned string into groups
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]})-${match[2]}-${match[3]}`;
        }

        return value;
    };

    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        const formattedValue = formatPhoneNumber(value);
        setPhoneNumber(formattedValue);
    };

    return (
        <div className="pt-10 px-16 flex flex-col gap-6">
            <div>
            Main Information
            </div>
            <div className="flex flex-row gap-6">
                <div>
                    <img className='h-24 w-24 rounded-full shadow-lg' src={profileImageUrl}></img>
                </div>
                <div className="flex flex-col gap-6">
                    <button onClick={handleButtonClick}>
                        <div className="flex p-1 flex-row border border-blue rounded-md gap-1 border-dashed">
                            <img className="h-6" src={upload_icon} />
                            <p className="text-blue">Upload Photo</p>
                        </div>
                    </button>
                    <button>
                        <div className="flex p-1 flex-row border border-blue rounded-md gap-1">
                            <img className="h-6" src={upload_icon} />
                            <p className="text-blue">Delete Photo</p>
                        </div>
                    </button>
                </div>
            </div>
            <form onSubmit={updateProfile}>
                <div className="flex flex-col gap-5">
                    <div className="flex flex-wrap gap-5">
                        <input className=" w-64 max-w-lg bg-lightgrey rounded-md py-2 pl-5" type="text" name="firstName" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        <input className=" w-64 max-w-lg bg-lightgrey rounded-md py-2 pl-5" type="text" name="lastName" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <input className=" w-64 max-w-lg bg-lightgrey rounded-md py-2 pl-5" type="phone" name="phoneNumber" placeholder="Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} />
                        <input className=" w-64 max-w-lg bg-lightgrey rounded-md py-2 pl-5" type="text" name="email" placeholder="Email Address" value={email} onChange={(e) => setEmial(e.target.value)}/>
                    </div>
                    <button className="self-start" type="submit">Submit</button>
                </div>
            </form>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    )
}

export default Account;