const General = ({ project }) => {
    return (
      <div className="flex flex-grow p-4">
        {project ? (
          <div>
            <h2 className="text-2xl font-bold mb-4">Project Details</h2>
            <p><strong>ID:</strong> {project.id}</p>
            <p><strong>Name:</strong> {project.name}</p>
            <p><strong>Status:</strong> {project.status}</p>
            <p><strong>Description:</strong> {project.description}</p>
            <p><strong>Created At:</strong> {project.createdAt}</p>
            <p><strong>Updated At:</strong> {project.updatedAt}</p>
            <p><strong>Owner:</strong> {project.owner}</p>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  }
  
  export default General;
  