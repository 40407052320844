import PillButton from "./button/pill";
import { useLocation, Link } from "react-router-dom";

const AccountPills = () => {
    const location = useLocation();

    return (
        <div className='flex flex-row pl-16'>
          <PillButton link='/dashboard/settings/account' active={location.pathname == '/dashboard/settings/account'}>
            Account Info
          </PillButton>
          <PillButton link='/dashboard/settings/property' active={location.pathname == '/dashboard/settings/property'}>
            Property Info
          </PillButton>
        </div>
    )
}

export default AccountPills;