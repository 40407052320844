// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserStatus = {
  "WAITLIST": "WAITLIST",
  "BETA": "BETA",
  "ADMIN": "ADMIN"
};

const ProjectStatus = {
  "CREATED": "Created",
  "IN": "In",
  "PROGRESS": "progress"
};

const { Users, Blog, Project, Quote } = initSchema(schema);

export {
  Users,
  Blog,
  Project,
  Quote,
  UserStatus,
  ProjectStatus
};