import React, { useState } from 'react';
import Link from './link';
import { NavLink } from 'react-router-dom';

const HamburgerMenu = () => {
    const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

    const handleClick = (event) => {
        event.preventDefault();
        setIsNavOpen(false)
        const targetElement = document.querySelector('#section1');
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - 128,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="flex items-center justify-between">
            <nav>
                <section className="MOBILE-MENU flex lg:hidden">
                    <div
                        className="space-y-2"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
                        <span className="block h-0.5 w-8 animate-pulse bg-blue"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-blue"></span>
                        <span className="block h-0.5 w-8 animate-pulse bg-blue"></span>
                    </div>
                    <div className={isNavOpen ? "flex flex-row justify-start absolute left-0 top-0 w-1/3 h-screen z-50 bg-white" : "hidden"}>
                        <div className="absolute top-0 right-0 px-8 py-8" onClick={() => setIsNavOpen(false)}>
                            <svg className="h-8 w-8 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </div>
                        <div className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-start gap-12 p-12 min-h-[250px]">
                            <Link href='#section1' setIsNavOpen={setIsNavOpen}>Home</Link>
                            <Link href='#section2' setIsNavOpen={setIsNavOpen}>What we do</Link>
                            <Link href='#section25' setIsNavOpen={setIsNavOpen}>Services</Link>
                            <Link href='#section3' setIsNavOpen={setIsNavOpen}>How it works</Link>
                            <Link href='#section4' setIsNavOpen={setIsNavOpen}>Pricing</Link>
                            <NavLink to='/blog'>Blog</NavLink>
                        </div>
                    </div>
                </section>
            </nav>
        </div>
    );
};

export default HamburgerMenu;
